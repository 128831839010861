import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import PrismicStyledText from 'components/PRISMIC/PrismicStyledText';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import utils, { Alignment } from '../../utils';

import type { JSX } from 'react';

/**
 * Props for `Body`.
 */
export type BodyProps = SliceComponentProps<Content.BodySlice>;

/**
 * Component for "Body" Slices.
 */
export const Body = ({ slice }: BodyProps): JSX.Element => {
  const textStyles = '[&_pre]:text-wrap';
  return (
    <div
      className={`${utils.getAlignClass(Alignment[slice.primary.align])} ${
        styles.copy
      } ${textStyles}`}
    >
      <PrismicStyledText
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        renderParagraphTags
        field={slice.primary.text}
      />
    </div>
  );
};

export default Body;
