import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import { crush } from '@tedconf/js-crushinator-helpers';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { PageBuilderHeading } from 'components/PRISMIC/slices/Heading/Heading';
import utils from 'components/PRISMIC/utils';
import Linkify from 'react-linkify';

const renderWithBreak = ({ children }: { children: React.ReactNode }) => {
  return (
    <Linkify>
      {utils.parseShortcodes(children)}
      <br />
    </Linkify>
  );
};

const renderParagraphWithBreak = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <Linkify>
      <p>
        {utils.parseShortcodes(children)}
        <br />
      </p>
    </Linkify>
  );
};

/**
 * PrismicRichText wrapper for inline elements
 */
// Headings in Roadrunner itself were raised by one, behaviour is copied as-is
const PrismicStyledText = ({
  components,
  renderParagraphTags = false,
  ...props
}: PrismicRichTextProps & { renderParagraphTags?: boolean }) => {
  return (
    <PrismicRichText
      components={{
        heading1: ({ children }) => (
          <PageBuilderHeading level="h2">{children}</PageBuilderHeading>
        ),
        heading2: ({ children }) => (
          <PageBuilderHeading level="h3">{children}</PageBuilderHeading>
        ),
        heading3: ({ children }) => (
          <PageBuilderHeading level="h4">{children}</PageBuilderHeading>
        ),
        heading4: ({ children }) => (
          <PageBuilderHeading level="h5">{children}</PageBuilderHeading>
        ),
        heading5: ({ children }) => (
          <PageBuilderHeading level="h6">{children}</PageBuilderHeading>
        ),
        heading6: ({ children }) => (
          <PageBuilderHeading level="h6">{children}</PageBuilderHeading>
        ),
        image: ({ node: { url, alt } }) => (
          // eslint-disable-next-line @next/next/no-img-element
          <img className={styles.image} src={crush(url)} alt={alt || ''} />
        ),
        paragraph: renderParagraphTags
          ? renderParagraphWithBreak
          : renderWithBreak,
        ...components
      }}
      {...props}
    />
  );
};

export default PrismicStyledText;
